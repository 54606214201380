import React, { FC } from 'react';
import SpectarLayout from '../../../components/PagesLayouts/SpectarLayout';
import SectionTitle from '../../../components/common/SectionTitle';
import ListItem from '../../../components/pagesComponents/CaseStudy/ListItem';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FixedButton from '../../../components/common/FixedButton';

const TechnicalSummary: FC = () => {
    return (
        <SpectarLayout>
            <div className="statflo-case-study__text statflo-case-study__text--large">
                <SectionTitle type="primary" title="Technical summary" />
                <ListItem margin="mt">
                    Designed and implement microservice application architecture on the back end.
                </ListItem>
                <ListItem>
                    Used Azure Virtual Machines service to deploy all the microservices for the
                    early first version of the back end.
                </ListItem>
                <ListItem>
                    Deployed on Docker with Kubernetes (K8s) Container Orchestration for all other
                    back end versions including the final ones.
                </ListItem>
                <ListItem>Deployed front end on Microsoft Azure Web App for Containers.</ListItem>
                <ListItem>
                    Developed a really custom BIM model converter and core processor by utilizes
                    Python with some Java-ported libraries.
                </ListItem>
                <ListItem>
                    Developed REST API for BIM model conversion and processing microservise.
                </ListItem>
                <ListItem>
                    Added support of any BIM models from market popular tools like Revit, AutoCAD,
                    Navis.
                </ListItem>
                <ListItem>
                    Provided both manual and automated <span>Quality control.</span>
                </ListItem>
                <ListItem>
                    Designed UX and UI for Spectar Cloud — both for the end-user environment and for
                    the internal operations team.
                </ListItem>
                <ListItem>
                    Developed application for HoloLens 2 and we are growing application for HoloLens
                    1.
                </ListItem>
                <Links />
            </div>
            <FixedButton />
        </SpectarLayout>
    );
};

export default TechnicalSummary;
